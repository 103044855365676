import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import Select from '@paljs/ui/Select';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  checkNumber,
  authCheck,
  ADMIN,
  getPageAccessFromState,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const InputPage = () => {
  const state = useContext(GlobalStateData);
  const [operationData, setOperationData] = useState();
  const [tableData, setTableData] = useState([]);
  const [tableFilter, setTableFilter] = useState('');
  const [groupOptions, setGroupOptions] = useState([]);
  const [defaultGroup, setdefaultGroup] = useState([]);
  const pageAccess = getPageAccessFromState(state);
  const [isSave, setSave] = useState(pageAccess.write === 1);
  useEffect(() => {
    authCheck(state.authData);
    getGroups();
    getApi();
  }, []);

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            onClick={() => {
              editOperation(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>
          {pageAccess.delete === 1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              onClick={() => {
                deleteOperation(row.id);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
        </div>
      ),
      maxWidth: '20%',
    },
    {
      name: 'Operations',
      selector: (row) => `${row.attributes.operationCode} ${row.attributes.description}`,
      sortable: true,
      maxWidth: '50%',
    },
    {
      name: 'Group',
      selector: (row) =>
        `${
          row.attributes.group
            ? row.attributes.group.data.attributes.groupCode + ' - ' + row.attributes.group.data.attributes.description
            : ''
        }`,
      sortable: true,
      maxWidth: '40%',
    },
  ];

  const filterColumns = [
    'operationCode',
    'description',
    'group.data.attributes.groupCode',
    'group.data.attributes.description',
  ];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = operationData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  const getGroups = () => {
    showLoader();
    axios
      .get(baseUri() + 'groups', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.groupCode + ' - ' + item.attributes.description, value: item.id });
        });
        setGroupOptions(options);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getApi = () => {
    showLoader();
    axios
      .get(baseUri() + 'operations?populate=group&sort[0]=id:desc', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setOperationData(response.data.data);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const [operationCode, setOperationCode] = useState('');
  const [description, setDescription] = useState('');
  const [group, setGroup] = useState('');
  const [pickId, setPickId] = useState('');

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'operationCode':
        setOperationCode(event.target.value);
        break;
      case 'description':
        setDescription(event.target.value);
        break;
    }
  };

  const groupCodeChange = (event) => {
    setGroup(event.value);
    setdefaultGroup({ label: event.label, value: event.value });
    pullDownReset('group');
  };

  const clearFormData = () => {
    setSave(pageAccess.write === 1);
    setDescription('');
    setOperationCode('');
    setPickId('');
    setdefaultGroup([]);
    setGroup('');
    resetRequired();
  };

  const postApi = () => {
    const postData = {
      data: {
        operationCode,
        description,
        group,
      },
    };
    let validate = requireCheck(postData, operationData, 'operationCode', pickId);
    if (!validate) {
      return false;
    }
    showLoader();
    if (pickId) {
      axios
        .put(baseUri() + 'operations/' + pickId, postData, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    } else {
      axios
        .post(baseUri() + 'operations/', postData, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const editOperation = (id) => {
    showLoader();
    clearFormData();
    axios
      .get(baseUri() + `operations/${id}?populate=*`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        response = response.data.data;
        setSave(pageAccess.edit === 1);
        setOperationCode(response.attributes.operationCode);
        setDescription(response.attributes.description);
        setGroup(response.attributes.group.data.id);
        setPickId(response.id);
        setdefaultGroup({
          label:
            response.attributes.group.data.attributes.groupCode +
            ' - ' +
            response.attributes.group.data.attributes.description,
          value: response.attributes.group.data.id,
        });
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const deleteOperation = (id) => {
    const removeGroup = window.confirm('Would you like to delete this operation?');
    if (removeGroup) {
      showLoader();
      setPickId(id);
      axios
        .delete(baseUri() + 'operations/' + id, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };
  return (
    <>
      <SEO title="operation Code Master" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Operation Code Master</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 6, md: 6 }}>
                  <label>
                    Select Group <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => groupCodeChange(e)}
                    options={groupOptions}
                    name="group"
                    id="group"
                    placeholder="Select Group *"
                    value={defaultGroup}
                    className="pull-down require"
                  />
                </Col>
                <Col breakPoint={{ xs: 6, md: 6 }}>
                  <label>
                    Operation Code <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      onChange={(e) => handleChange(e)}
                      placeholder="Operation Code *"
                      name="operationCode"
                      value={operationCode}
                      className="operationCode require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12 }}>
                  <label>
                    Description <span className="red">*</span>
                  </label>
                  <Input fullWidth>
                    <textarea
                      rows={5}
                      onChange={(e) => handleChange(e)}
                      placeholder="Description *"
                      value={description}
                      name="description"
                      className="description require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button
                    status="Success"
                    type="button"
                    disabled={!isSave}
                    className="btnrad"
                    shape=""
                    halfWidth
                    onClick={postApi}
                  >
                    Save
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Operation Code Master</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default InputPage;
